// Setup
import React, { useState } from 'react'

// Vendor
import { defineMessages, FormattedNumber } from 'react-intl'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import Paper from '@material-ui/core/Paper'

// WeSpire
import { Heading } from 'components/ui/heading'
import cx from 'classnames'
import { intl } from 'utilities/localization'
import WeSpireAPI from 'utilities/wespire_api'
import { displayBanner } from 'redux/dispatchers'
import { client } from 'utilities/we_apollo'
import MgmtPanelPage from 'setup/application/page'
import Icon from 'components/ui/icon'
import LinkButton from 'components/ui/link_button'
import Stack from 'components/ui/stack'
import { UsersTable } from './users_table'
import { InvoicesTable } from './invoices_table'

const { formatMessage } = intl

const BRAND_QUERY = gql`
  query brandQuery {
    brand {
      brandGivingaAccountUsername
      corporateAccountBalance
      givingaInvoiceCustomerId
    }
  }
`

const messages = defineMessages({
  corporateAccountBalancebalance: {
    defaultMessage: 'CORPORATE ACCOUNT BALANCE',
    id: 'givingAccountsAdministrations.balance',
  },
  downloadTemplate: {
    defaultMessage: 'Download Template',
    id: 'groupMembersUpload.downloadTemplate',
  },
  manageFunds: {
    defaultMessage: 'MANAGE FUNDS',
    id: 'givingAccountsAdministrations.manageFunds'
  },
  processingUpload: {
    defaultMessage:
      'We are processing your file upload and will send you a confirmation email once the process has completed.',
    id: 'groupMembersUpload.processingUpload',
  },
})

const GivingAccountsAdministrations = () => {
  const { data, loading } = useQuery(BRAND_QUERY, {
    client,
    notifyOnNetworkStatusChange: true
  })
  const corporateAccountBalance = data?.brand?.corporateAccountBalance
  const invoiceCustomerId = data?.brand?.givingaInvoiceCustomerId
  const brandGivingaAccountUsername = data?.brand?.brandGivingaAccountUsername
  const [selectedFile, setSelectedFile] = useState(null)
  const [selectOption, setSelectOption] = useState('')

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    if (file && file.type === 'text/csv') {
      setSelectedFile(file)
    } else {
      alert('Please upload a CSV file.')
    }
  }

  const handleSelectChange = (event) => {
    setSelectOption(event.target.value)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    if (!selectedFile) {
      alert('Please upload a CSV file.')
      return
    }
    if (!selectOption) {
      alert('Please select an option.')
      return
    }

    const formData = new FormData()
    formData.append('file', selectedFile)
    formData.append('option', selectOption)

    WeSpireAPI()
      .post('/giving_accounts_administrations/upload', formData)
      .then((response) => {
        setSelectedFile(null)
        if (response.status === 200) {
          displayBanner({
            content: formatMessage(messages.processingUpload),
            variant: 'success',
          })
        }
      })
      .catch((error) => {
        displayBanner({
          as: 'div',
          content: error?.response?.data?.errors,
          variant: 'error',
        })
      })
  }
  return (
    <MgmtPanelPage
      contentHeaderProps={{
        'data-test': 'personal-grant-funds-index-title',
      }}
      title='Giving Accounts Administration'
    >
      {() => (
        <Stack className="p-3" space={0}>
          <Stack as="section" className="mt-2" space={4}>
            <Heading level={2} variant={2}>
              {formatMessage(messages.corporateAccountBalancebalance)}
            </Heading>

            <Stack className="row mx-0" space={0}>
              <Paper
                className={cx('col-6 col-md-3 p-3')}
              >
                { corporateAccountBalance &&
                  <span className="fs-6 text-orange">
                    <FormattedNumber
                      id="corporate-balance"
                      currency="USD"
                      style="currency"
                      value={corporateAccountBalance}
                    />
                  </span>
                }
                <Heading
                  className="fs-2 fw-semi-bold text-uppercase text-black-3"
                  level={3}
                >
                  {formatMessage(messages.corporateAccountBalancebalance)}
                </Heading>
              </Paper>
            </Stack>
            <InvoicesTable invoiceCustomerId={invoiceCustomerId} brandGivingaAccountUsername={brandGivingaAccountUsername} />
            <Stack as={Paper} className="border border-black-5" space={0}>
              <Stack className="d-flex px-4 py-3 bg-black-6">
                <Heading
                  className="fs-2 text-uppercase"
                  data-test="manage-funds"
                  level={3}
                >
                  {formatMessage(messages.manageFunds)}
                </Heading>
              </Stack>
              <Stack className="px-4 py-3" space={2}>
                <LinkButton
                  className="text-blue fs-2 ml-auto"
                  to={`/documents/giving_account_administration_template.csv`}
                  variant="text"
                >
                  <Icon
                    className="fs-4 mr-1"
                    iconName="export"
                    title={formatMessage(messages.downloadTemplate)}
                  />
                  {formatMessage(messages.downloadTemplate)}
                </LinkButton>
                <Stack className="d-flex">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col mt-5">
                        <label htmlFor="fileInput" style={{display: 'block'}}>Upload CSV file:</label>
                        <input
                          type="file"
                          id="fileInput"
                          accept=".csv"
                          onChange={handleFileChange}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col mt-5">
                        <label htmlFor="selectInput" style={{display: 'block'}}>Select an option:</label>
                        <select id="selectInput" value={selectOption} onChange={handleSelectChange}>
                          <option value="">--Please choose an option--</option>
                          <option value="to_employee_account">To Employee Accounts</option>
                          <option value="to_corporate_account">From Employee Accounts</option>
                        </select>
                      </div>
                    </div>
                    <button type="submit" className='mt-5'>Submit</button>
                  </form>
                </Stack>
              </Stack>
            </Stack>
            <UsersTable />
          </Stack>
        </Stack>
      )}
    </MgmtPanelPage>
  )
}

export default GivingAccountsAdministrations
