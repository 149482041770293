// Setup
import React from 'react'
import { FormattedNumber, defineMessages } from 'react-intl'
import PropTypes from 'prop-types'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

// WeSpire
import { intl } from 'utilities/localization'
import Link from 'components/shared/link'
import { dateTimeHourMinute } from 'utilities/date_formatter'


// Vendor
import DownloadIcon from '@mui/icons-material/Download'
import PaymentIcon from '@mui/icons-material/Payment'

const { formatMessage, formatDate } = intl

const messages = defineMessages({
  editFunds: {
    defaultMessage: 'Edit Funds',
    id: 'UserRow.editFunds',
  },
})

export const InvoiceRow = ({
  id,
  total,
  status,
  invoicePdf,
  hostedInvoiceUrl,
  created,
  ...props
}) => {
  return (
    <TableRow
      data-test="invoice-row"
      hover
      key={id}
      tabIndex={-1}
      {...props}
    >
      <TableCell align="left">
        {id}
      </TableCell>
      <TableCell align="left">
        <FormattedNumber
          currency="USD"
          style="currency"
          value={total/100}
        />
      </TableCell>
      <TableCell align="left">
        { dateTimeHourMinute(created * 1000) }
      </TableCell>
      <TableCell align="right">
        {status}
      </TableCell>
      <TableCell align="right">
      {  hostedInvoiceUrl &&
          <Link to={hostedInvoiceUrl}>
            {<PaymentIcon className="text-md text-blue" />}
          </Link>
      }
      </TableCell>
      <TableCell align="right">
        { invoicePdf &&
          <Link to={invoicePdf}>
            {<DownloadIcon className="text-md text-blue" />}
          </Link>
  }
      </TableCell>
    </TableRow>
  )
}

InvoiceRow.propTypes = {
  id: PropTypes.string.isRequired,
  total: PropTypes.string,
  status: PropTypes.string.isRequired,
  invoicePdf: PropTypes.string,
  hostedInvoiceUrl: PropTypes.string,
}
